
.image-wrapper {
    z-index: 1;
    position: relative;
}

.image-wrapper img {
    height: auto;
    width: 100%;
    object-fit: contain;
    transition: 0.2s;
    min-width: 100%;
    min-height: 100%;
    max-height: 100vh;
    cursor: pointer;
    -webkit-user-select: auto;
    -webkit-touch-callout: auto;
}

