
.control-bar-container {
    position: fixed;
    left: 5px;
    bottom: 20%;
    display: flex;
    width: 40px;
    z-index: 10;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.control-button {
    padding: 10px 0px;
    cursor: pointer;
    opacity: 1;
}

