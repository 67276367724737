
.video-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
}

.video-wrapper video {
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    transition: 0.1s;
}

.video-overlay-controls-stopper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.video-progress-bar-container {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 10px;
    z-index: 4;
    width: 100%;
}

.left-incr-video,
.right-incr-video {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 40%;
    height: 100%;
}

.left-incr-video {
    left: 0;
}

.right-incr-video {
    right: 0;
}