.ad-place-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fade-in 0.3s linear forwards;
}

@media screen and (orientation: portrait) {
    .ad-wrapper {
        flex-direction: column;
    }
}




@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.video-alt-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exo-native-widget-item-content-box {
    padding-left: 10px;
    width: calc(100% -10px);
}

.exo-native-widget-item-brand {
    margin-left: 5px;
}

.video-inner-alt-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.video-inner-alt-wrapper video-js {
    width: 100%;
}

.video-inner-alt-wrapper video-js video {
    max-height: 100vh;
}

.ad-place-holder h1 {
    color: white;
    font-size: 7rem;
}

.ad-place-holder h1 span {
    color: red;
}