
.video-component-play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    transition: 0.1s;
    z-index: 10;
}

.video-controls-overlay-inner {
    width: 90px;
    height: 90px;
    max-width: 90px;
    max-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.video-component-play-overlay:hover {
    opacity: 1;
}

.video-component-play-overlay svg {
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.increment-indicator {
    text-align: center;
    font-size: 4rem;
    color: white;
}