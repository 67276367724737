.menu-container {
    background-color: var(--main-bg-color);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
}

.outer-catagories-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    justify-content: center;
}

.catagory-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
}